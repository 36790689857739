import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";

import { SharedComponentsModule } from "../shared/components/shared-components.module";
import { AngularMaterialModule } from "../shared/material/material.module";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";

import { BrandFormComponent } from "./brand-form/brand-form.component";
import { ThanksDialogComponent } from "./thanks-dialog/thanks-dialog.component";

export const routes: Routes = [
  {
    path: "",
    component: BrandFormComponent,
  },
];

@NgModule({
  declarations: [BrandFormComponent, ThanksDialogComponent],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    AngularMaterialModule,
    SharedPipesModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
  ],
})
export class ReferACampaignEditModule {}
