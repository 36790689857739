import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface ThanksDialogData {
  hasAContact: boolean;
}

@Component({
  selector: "app-thanks-dialog",
  templateUrl: "./thanks-dialog.component.html",
  styleUrl: "./thanks-dialog.component.scss",
})
export class ThanksDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) protected readonly data: ThanksDialogData,
  ) {}
}
