<h2 mat-dialog-title>
  <mat-icon>thumb_up</mat-icon>{{ "thanksDialog.thankYou" | translate }}
</h2>
<mat-dialog-content>
  <p>{{ "thanksDialog.thankYou" | translate }}</p>
  <p>
    {{
      (data.hasAContact
        ? "thanksDialog.messageSent"
        : "thanksDialog.receivedInformation"
      ) | translate
    }}
  </p>
  <p>
    {{ "thanksDialog.ifYouWantReach" | translate }}
    <span>info&#64;socialpals.de</span>
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="button-orange dismiss" [mat-dialog-close]="true">
    {{ "thanksDialog.close" | translate }}
  </button>
</mat-dialog-actions>
