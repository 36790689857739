import { SerializableObject } from "./serializable-object";

export class PartnerBrandRecommendation extends SerializableObject {
  brandName!: string;
  contactFirstName!: string;
  contactLastName!: string;
  contactEmail!: string;
  contactPhone!: string;
  contactMessage!: string;
  hasAContact!: boolean;
  isDefaultMessage!: boolean;
}
