import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { PartnerBrandRecommendation } from "../../shared/models/partnerBrandRecommendation";
import { PartnerBrandRecommendationService } from "../partner-brand-recommendation.service";
import { PartnerService } from "../../shared/services/api/partner.service";
import { NotificationService } from "../../shared/services/notification.service";
import { ThanksDialogComponent } from "../thanks-dialog/thanks-dialog.component";
import { cloneWith } from "../../shared/services/clonable";
import { assert } from "../../shared/utils/assert";

@Component({
  selector: "app-brand-form",
  templateUrl: "./brand-form.component.html",
  styleUrl: "./brand-form.component.scss",
  providers: [PartnerBrandRecommendationService],
})
export class BrandFormComponent implements OnInit, OnDestroy {
  protected brand = cloneWith(new PartnerBrandRecommendation(), {
    hasAContact: true,
    isDefaultMessage: true,
  });
  protected nameControl = new UntypedFormControl();
  protected sending = false;
  protected submitted = false;

  private readonly subscription = new Subscription();

  constructor(
    private readonly partnerService: PartnerService,
    private readonly partnerBrandRecommendationService: PartnerBrandRecommendationService,
    private readonly notificationService: NotificationService,
    private readonly dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.nameControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe((value) => (this.brand.brandName = value)),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected send(): void {
    if (!this.sending && this.isValid(this.brand)) {
      if (this.brand.contactMessage) {
        this.brand.isDefaultMessage = false;
      }
      this.sendRecommendation();
    } else {
      this.submitted = true;
    }
  }

  protected sendWithoutContact(): void {
    if (!this.sending) {
      this.brand.hasAContact = false;
      this.sendRecommendation();
    }
  }

  private isValid(brand: PartnerBrandRecommendation): boolean {
    return Boolean(
      brand.brandName &&
        brand.contactFirstName &&
        brand.contactLastName &&
        brand.contactEmail,
    );
  }

  private sendRecommendation(): void {
    this.sending = true;

    assert(this.partnerService.lastSelectedPartnerId, "Partner ID is required");

    this.partnerBrandRecommendationService
      .recommendABrand(this.partnerService.lastSelectedPartnerId, this.brand)
      .subscribe({
        next: () => {
          this.sending = false;
          this.showThanksDialog();
        },
        error: () => {
          this.sending = false;
          this.notificationService.error("The recommendation failed to send");
        },
      });
  }

  private showThanksDialog(): void {
    this.dialog
      .open(ThanksDialogComponent, {
        width: "572px",
        data: {
          hasAContact: this.brand.hasAContact,
        },
      })
      .afterClosed()
      .subscribe(() => {
        window.location.reload();
      });
  }
}
