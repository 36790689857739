<div class="content-wrapper">
  <h1>
    <mat-icon>record_voice_over</mat-icon
    >{{ "partner.referBrand.title" | translate }}
  </h1>
  <p class="description-text">
    {{ "partner.referBrand.subtitle" | translate }}
  </p>
  <div class="refer-form-wrapper">
    <img class="refer-banner" src="/assets/images/as_palgirl_success.svg" />
    <form name="brand-form" #f="ngForm" novalidate>
      <p class="section-title">
        {{ "partner.referBrand.sectionTitle" | translate }}
      </p>
      <div class="row">
        <div class="col width-100">
          <mat-form-field>
            <mat-label>{{
              "partner.referBrand.brandName" | translate
            }}</mat-label>
            <input matInput name="brandName" [formControl]="nameControl" />
          </mat-form-field>
        </div>
      </div>
      <ng-container *ngIf="brand.brandName">
        <button
          class="button-orange send-button"
          id="sendWithoutContact"
          type="submit"
          (click)="sendWithoutContact()"
        >
          {{ "partner.referBrand.noContact" | translate }}
        </button>
        <p class="section-subtitle">
          {{ "partner.referBrand.repInfo" | translate }}
        </p>
        <div class="row">
          <div class="col width-50">
            <mat-form-field>
              <mat-label>{{
                "partner.referBrand.firstName" | translate
              }}</mat-label>
              <input
                matInput
                [(ngModel)]="brand.contactFirstName"
                name="contactFirstName"
                [required]="brand.hasAContact"
              />
              <mat-error *ngIf="!brand.contactFirstName && submitted">{{
                "shared.requiredField" | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col width-50">
            <mat-form-field>
              <mat-label>{{
                "partner.referBrand.lastName" | translate
              }}</mat-label>
              <input
                matInput
                [(ngModel)]="brand.contactLastName"
                name="contactLastName"
                [required]="brand.hasAContact"
              />
              <mat-error *ngIf="!brand.contactLastName && submitted">{{
                "shared.requiredField" | translate
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field>
              <mat-label>{{
                "partner.referBrand.emailAddress" | translate
              }}</mat-label>
              <input
                matInput
                [(ngModel)]="brand.contactEmail"
                name="contactEmail"
                [required]="brand.hasAContact"
              />
              <mat-error *ngIf="!brand.contactEmail && submitted">{{
                "shared.requiredField" | translate
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="phone-number">
              <mat-label>{{
                "partner.referBrand.phone" | translate
              }}</mat-label>
              <input
                matInput
                [(ngModel)]="brand.contactPhone"
                name="contactPhone"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field>
              <mat-label>
                {{ "shared.message" | translate }} ({{
                  "shared.optional" | translate
                }}
                - 140/140)
              </mat-label>
              <textarea
                [cdkTextareaAutosize]
                matInput
                [placeholder]="
                  'partner.referBrand.messagePlaceholder' | translate
                "
                [(ngModel)]="brand.contactMessage"
                name="contactMessage"
              >
              </textarea>
            </mat-form-field>
          </div>
        </div>
        <button
          class="button-orange send-button"
          id="send"
          type="submit"
          (click)="send()"
        >
          {{ "partner.referBrand.send" | translate }}
        </button>
      </ng-container>
    </form>
  </div>
</div>
