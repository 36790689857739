import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { PartnerBrandRecommendation } from "../shared/models/partnerBrandRecommendation";
import { HttpClient } from "../shared/services/http-client";

@Injectable()
export class PartnerBrandRecommendationService {
  path = "partner";

  constructor(private http: HttpClient) {}

  recommendABrand(
    partnerId: number,
    brand: PartnerBrandRecommendation,
  ): Observable<PartnerBrandRecommendation> {
    return this.http
      .post(`${this.path}/${partnerId}/brand_recommendation`, brand)
      .pipe(map(({ body }) => new PartnerBrandRecommendation(body)));
  }
}
